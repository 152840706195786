import { defineStore } from 'pinia'
import _ from "lodash";

let initialState ={
    booked_in_reg: "",
    vehicle_id: "",
    customer_id: "",
    booked_in_by: "",
    email: "",
    name: "",
    phonenum: "",
    cpref: "Email",
    dep_amount: "",
    dep_ref: "",
    vehicle: "",
    reg: "",
    vin: "",
    has_ghost_alarm: false,
    ghost_alarm_code: null,
    engine_code: "Select One",
    engine_code_other: "",
    gearbox: "Select One",
    gearbox_other: "",
    gearbox_type: "Select One",
    gearbox_type_other: "",
    drivetrain: "Select One",
    tun: false,
    ws: false,
    fab: false,
    tvvt: false,
    bodyshop: false,
    ecu_num: "",
    tuned_by: "Select One",
    tuned_by_other: "",
    claimed_power: "",
    claimed_torque: "",
    current_power: "",
    current_torque: "",
    turbocharger: "Select One",
    injectors: "Select One",
    pump: "Select One",
    power_tune: "No",
    map_sensor: "Select One",
    maf_stock: "Stock",
    intercooler: "Select One",
    pipework: "Select One",
    camshaft: "Select One",
    cylinder_head: "Select One",
    clutch_flywheel: "Select One",
    turbocharger_other: "",
    injectors_other: "",
    pump_other: "",
    map_sensor_other: "",
    intercooler_other: "",
    pipework_other: "",
    camshaft_other: "",
    cylinder_head_other: "",
    clutch_flywheel_other: "",
    other_mods: "",
    gearbox_mods: "",
    maf: "On",
    egr_hardware: "On",
    egr_software: "On",
    asv: "On",
    cat: "On",
    dpf: "On",
    dpf_sensors: "On",
    swirl_flaps: "On",
    hardcut: "No",
    launch: "No",
    hot_start_fix: "No",
    adblue: "NA",
    left_foot_braking: "NA",
    track_use: false,
    gearbox_tune: "NA",
    expected_power: "",
    expected_peak_torque: "",
    smoke_level: "Smoke Free",
    dropoff_date: null,
    dropoff_time: null,
    start_date: null,
    start_time: null,
    finish_date: null,
    finish_time: null,
    collect_date: null,
    collect_time: null,
    customer: "",
    notes: "",
    bhp: "",
    torque: "",
    stopStart: "NA",
    parts: [],
    old_lab: false,
    prev_tuned: false,
    is_warranty: false,
    fuel: ""
};
export const useBookingStore = defineStore('booking', {
    //state: () => (state),
    state: () => {
        return _.cloneDeep(initialState)
    },
    actions: {
        setVehicleId(value) {
            this.vehicle_id = value;
        },
        setCustomerId(value) {
            this.customer_id = value;
        },
        setBookedInBy(value) {
            this.booked_in_by = value;
        },
        setBookedInReg(value) {
            this.booked_in_reg = value;
        },
        setEmail(value) {
            this.email = value;
        },
        setName(value) {
            this.name = value;
        },
        setPhone(value) {
            this.phonenum = value;
        },
        setContactPref(value) {
            this.cpref = value;
        },
        setDepAmount(value) {
            this.dep_amount = value;
        },
        setDepRef(value) {
            this.dep_ref = value;
        },
        setVehicle(value) {
            this.vehicle = value;
        },
        setReg(value) {
            this.reg = value;
        },
        setVin(value) {
            this.vin = value;
        },
        setEngineCode(value) {
            this.engine_code = value;
        },
        setEngineCodeOther(value) {
            this.engine_code_other = value;
        },
        setGearbox(value) {
            this.gearbox = value;
        },
        setGearboxOther(value) {
            this.gearbox_other = value;
        },
        setGearboxType(value) {
            this.gearbox_type = value;
        },
        setGearboxTypeOther(value) {
            this.gearbox_type_other = value;
        },
        setDrivetrain(value) {
            this.drivetrain = value;
        },
        setTuning(value) {
            this.tun = value;
        },
        setWorkshop(value) {
            this.ws = value;
        },
        setFabrication(value) {
            this.fab = value;
        },
        setTvvt(value) {
            this.tvvt = value;
        },
        setBodyshop(value) {
            this.bodyshop = value;
        },
        setIsWarranty(value) {
            this.is_warranty = value;
        },
        setECUNum(value) {
            this.ecu_num = value;
        },
        setTunedBy(value) {
            this.tuned_by = value;
        },
        setTunedByOther(value) {
            this.tuned_by_other = value;
        },
        setClaimedPower(value) {
            this.claimed_power = value;
        },
        setClaimedTorque(value) {
            this.claimed_torque = value;
        },
        setCurrentPower(value) {
            this.current_power = value;
        },
        setCurrentTorque(value) {
            this.current_torque = value;
        },
        setTurbocharger(value) {
            this.turbocharger = value;
        },
        setTurbochargerOther(value) {
            this.turbocharger_other = value;
        },
        setInjectors(value) {
            this.injectors = value;
        },
        setInjectorsOther(value) {
            this.injectors_other = value;
        },
        setPump(value) {
            this.pump = value;
        },
        setPumpOther(value) {
            this.pump_other = value;
        },
        setPowerTune(value) {
            this.power_tune = value;
        },
        setMapSensor(value) {
            this.map_sensor = value;
        },
        setMapSensorOther(value) {
            this.map_sensor_other = value;
        },
        setMafStock(value) {
            this.maf_stock = value;
        },
        setIntercooler(value) {
            this.intercooler = value;
        },
        setIntercoolerOther(value) {
            this.intercooler_other = value;
        },
        setPipework(value) {
            this.pipework = value;
        },
        setPipeworkOther(value) {
            this.pipework_other = value;
        },
        setCamshaft(value) {
            this.camshaft = value;
        },
        setCamshaftOther(value) {
            this.camshaft_other = value;
        },
        setCylHead(value) {
            this.cylinder_head = value;
        },
        setCylHeadOther(value) {
            this.cylinder_head_other = value;
        },
        setClutchFlywheel(value) {
            this.clutch_flywheel = value;
        },
        setClutchFlywheelOther(value) {
            this.clutch_flywheel_other = value;
        },
        setOtherMods(value) {
            this.other_mods = value;
        },
        setGearboxMods(value) {
            this.gearbox_mods = value;
        },
        setMaf(value) {
            this.maf = value;
        },
        setEGRHardware(value) {
            this.egr_hardware = value;
        },
        setEGRSoftware(value) {
            this.egr_software = value;
        },
        setASV(value) {
            this.asv = value;
        },
        setCat(value) {
            this.cat = value;
        },
        setDPF(value) {
            this.dpf = value;
        },
        setDPFSensors(value) {
            this.dpf_sensors = value;
        },
        setSwirlFlaps(value) {
            this.swirl_flaps = value;
        },
        setHardcut(value) {
            this.hardcut = value;
        },
        setLaunch(value) {
            this.launch = value;
        },
        setHotStartFix(value) {
            this.hot_start_fix = value;
        },
        setAdblue(value) {
            this.adblue = value;
        },
        setLeftFootBraking(value) {
            this.left_foot_braking = value;
        },
        setTrackUse(value) {
            this.track_use = value;
        },
        setGearboxTune(value) {
            this.gearbox_tune = value;
        },
        setExpectedPower(value) {
            this.expected_power = value;
        },
        setExpectedPeakTorque(value) {
            this.expected_peak_torque = value;
        },
        setSmokeLevel(value) {
            this.smoke_level = value;
        },
        setDropoffDate(value) {
            this.dropoff_date = value;
        },
        setDropoffTime(value) {
            this.dropoff_time = value;
        },
        setStartDate(value) {
            this.start_date = value;
        },
        setStartTime(value) {
            this.start_time = value;
        },
        setFinishDate(value) {
            this.finish_date = value;
        },
        setFinishTime(value) {
            this.finish_time = value;
        },
        setCollectDate(value) {
            this.collect_date = value;
        },
        setCollectTime(value) {
            this.collect_time = value;
        },
        setCustomer(value) {
            this.customer = value;
        },
        setNotes(value) {
            this.notes = value;
        },
        setBHP(value) {
            this.bhp = value;
        },
        setTorque(value) {
            this.torque = value;
        },
        setStopStart(value) {
            this.stopStart = value;
        },
        setParts(value) {
            this.parts = value;
        },
        setHasGhostAlarm(value) {
            this.has_ghost_alarm = value;
        },
        setGhostAlarmCode(value) {
            this.ghost_alarm_code = value;
        },
        setOldLab(value) {
            this.old_lab = value;
        },
        setPrevTuned(value) {
            this.prev_tuned = value;
        },
        setState(value) {
            Object.assign(value)
        }
    }
})
